"use client"

import { useEffect } from "react"
import { useRouter } from "next/navigation"

import { getCacheReloadTimer, getCacheSet, setCacheSet } from "@/lib/cookies.client"

export default function CheckCache() {
  const router = useRouter()
  useEffect(() => {
    const cacheSet = getCacheSet()
    const cacheTimer = getCacheReloadTimer()
    if (!cacheSet) {
      const timeout = setTimeout(() => {
        setCacheSet()
        // router.refresh()
      }, cacheTimer)
      return () => clearTimeout(timeout)
    }
  }, [])
  return <></>
}
